import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReferenceDataFacade } from '@ifhms/admin/web/domain/state/reference-data';
import { FieldType, FieldTypeConfig, FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyTypesEnum, SersiFormlyFieldProps, SersiSelectListItem } from '@sersi/angular/formly/core';

import { PricingMethodCodesType, PricingMethodTypeInputs } from '../../interfaces';

@Component({
  selector: 'ifhms-pricing-select-type',
  templateUrl: './pricing-select-type.component.html',
  styleUrls: ['./pricing-select-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PricingSelectTypeComponent extends FieldType<FieldTypeConfig> implements OnInit, FormlyExtension {

  private readonly COST_PLUS_DECIMAL_PLACES = 2;
  private readonly FIXED_PRICE_DECIMAL_PLACES = 4;
  private fieldConfig: FormlyFieldConfig;
  private selectedPricingMethod: PricingMethodCodesType | null;

  private get fieldProps(): SersiFormlyFieldProps {
    return this.fieldConfig?.props || {} as SersiFormlyFieldProps;
  }

  fields: FormlyFieldConfig[];

  constructor(private referenceDataFacade: ReferenceDataFacade) {
    super();
  }

  onPopulate(field: FormlyFieldConfig): void {
    // skip if already initialized
    if (field.fieldGroup) return;
    this.fieldConfig = field;
    this.fieldConfig.fieldGroup = [
      {
        fieldGroupClassName: 'pricing-method-group grid-column-layout-2',
        fieldGroup: [
          this.setPricingMethod(),
          this.pricingMethodPercent(),
          this.pricingMethodFixed()
        ]
      }
    ]
  }

  ngOnInit(): void {
    this.referenceDataFacade.getPricingMethods();
  }

  private setPricingMethod(): FormlyFieldConfig {
    const fieldKey = this.fieldProps[PricingMethodTypeInputs.METHOD_DROPDOWN_KEY];
    return {
      className: 'pricing-method-select',
      type: FormlyTypesEnum.SINGLE_SELECT,
      key: fieldKey,
      props: {
        showClear: !this.fieldProps['required'],
        required: this.fieldProps['required'] || false,
        label: this.fieldProps[PricingMethodTypeInputs.METHOD_DROPDOWN_LABEL],
        items$: this.referenceDataFacade.pricingMethods$,
        disabled: this.fieldProps['disabled'] || false,
        optionsLoaded: (options: SersiSelectListItem[]): void => {
          const value = this.formControl.value?.[fieldKey];
          if (value && options?.length && !this.selectedPricingMethod) {
            const selectedOption = options.find(opt => opt.id === value);
            this.selectedPricingMethod = selectedOption ? (selectedOption.code as PricingMethodCodesType) : null;
            this.field.props['formOptions']?.detectChanges(this.field);
          }
        },
        change: (_, item?: SersiSelectListItem): void => {
          if (item) {
            this.selectedPricingMethod = item.code as PricingMethodCodesType;
          }
        }
      }
    }
  }

  private pricingMethodPercent(): FormlyFieldConfig {
    return {
      className: 'pricing-method-input',
      key: this.fieldProps[PricingMethodTypeInputs.VALUE_KEY],
      name: 'pricingMethodPercent',
      type: FormlyTypesEnum.NUMBER_INPUT,
      props: {
        label: this.fieldProps[PricingMethodTypeInputs.VALUE_LABEL],
        required: this.fieldProps['required'] || false,
        disabled: this.fieldProps['disabled'] || false,
        showClear: !this.fieldProps['required'],
        iconSuffix: 'pi-percentage',
        maxFractionDigits: this.COST_PLUS_DECIMAL_PLACES,
        min: 0,
        allowInvalidInput: false
      },
      expressions: {
        hide: (field): boolean =>
          !field.model.pricingMethodId || this.selectedPricingMethod !== PricingMethodCodesType.CostPlus
      }
    };
  }

  private pricingMethodFixed(): FormlyFieldConfig {
    return {
      className: 'pricing-method-input',
      key: this.fieldProps[PricingMethodTypeInputs.VALUE_KEY],
      name: 'pricingMethodFixed',
      type: FormlyTypesEnum.CURRENCY_INPUT,
      props: {
        label: this.fieldProps[PricingMethodTypeInputs.VALUE_LABEL],
        required: this.fieldProps['required'] || false,
        disabled: this.fieldProps['disabled'] || false,
        maxFractionDigits: this.FIXED_PRICE_DECIMAL_PLACES,
        minFractionDigits: 2,
        allowNegativeNumbers: false
      },
      expressions: {
        hide: (field) => !field.model.pricingMethodId || this.selectedPricingMethod !== PricingMethodCodesType.FixedPrice
      }
    };
  }

}
