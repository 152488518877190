import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { dateToStartOfDate } from '@common/angular/utils';

import { ProcedureCreateDto, ProcedureDto, ProcedureUpdateDto } from '@ifhms/models/admin';
import { map, Observable } from 'rxjs';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class OperationProceduresService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  add(operationId: string, procedureCreate: ProcedureCreateDto): Observable<ProcedureDto> {
    const updatedProcedure = this.adjustDates(procedureCreate);
    return this.http.post<ProcedureDto>(`${this.apiUrl}/Operation/${operationId}/Procedures`, updatedProcedure);
  }

  get(operationId: string, procedureId: string): Observable<ProcedureDto> {
    return this.http.get<ProcedureDto>(`${this.apiUrl}/Operation/${operationId}/Procedures/${procedureId}`).pipe(
      map((x: ProcedureDto) => {

        for (const detail of x.details) {
          detail.effectiveFromDate = new Date(detail.effectiveFromDate);
          detail.effectiveToDate &&= new Date(detail.effectiveToDate as unknown as Date);
          detail.lastBilled &&= new Date(detail.lastBilled as unknown as Date);
        }

        return x;
      })
    );
  }

  getList(operationId: string): Observable<ProcedureDto[]> {
    return this.http.get<ProcedureDto[]>(`${this.apiUrl}/Operation/${operationId}/Procedures/List`);
  }

  delete(operationId: string, procedureId: string): Observable<unknown> {
    return this.http.delete(`${this.apiUrl}/Operation/${operationId}/Procedures/${procedureId}`);
  }

  update(operationId: string, procedure: ProcedureUpdateDto): Observable<ProcedureDto> {
    const updatedProcedureCharge = this.adjustDates(procedure);
    return this.http.put<ProcedureDto>(`${this.apiUrl}/Operation/${operationId}/Procedures`, updatedProcedureCharge);
  }

  toggleActiveStatus(operationId: string, procedureId: string): Observable<ProcedureDto> {
    return this.http.put<ProcedureDto>(`${this.apiUrl}/Operation/${operationId}/Procedures/ToggleActiveStatus/${procedureId}`, null);
  }

  isCodeUnique(operationId: string, code: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { code }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Operation/${operationId}/Procedures/IsCodeValid`, options);
  }

  private adjustDates(procedure: ProcedureDto | ProcedureCreateDto | ProcedureUpdateDto): ProcedureDto | ProcedureCreateDto | ProcedureUpdateDto {
    const updatedProcedureCharge: ProcedureDto | ProcedureCreateDto | ProcedureUpdateDto = { ...procedure };
    updatedProcedureCharge.details = updatedProcedureCharge.details.map((detail) => ({
      ...detail,
      effectiveFromDate: dateToStartOfDate(detail.effectiveFromDate)!,
      effectiveToDate: dateToStartOfDate(detail.effectiveToDate)
    }));

    return updatedProcedureCharge;
  }
}
