import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonPermissionsMap, CommonPermissionsService } from '@common/angular/permissions';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { getCurrentRouteWithData } from '@common/angular/utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanyPermissionsService extends CommonPermissionsService {

  constructor(
    private companyFacade: CompanyFacade,
    private router: Router
  ) {
    super();
    this.permissions$ = this.companyFacade.companyPermissions$ as Observable<CommonPermissionsMap | null>;
  }

  protected override getLoadedPermissions(): CommonPermissionsMap | null {
    return this.loadedPermissions || this.getRouteResolverPermissions();
  }

  private getRouteResolverPermissions(): CommonPermissionsMap | null {
    const routeData = getCurrentRouteWithData(this.router);
    return routeData['snapshot']?.data?.company?.permissions;
  }

}
