import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { PricingSelectTypeComponent } from './pricing-select-type.component';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule,
    ReactiveFormsModule
  ],
  declarations: [ PricingSelectTypeComponent ],
  exports: [ PricingSelectTypeComponent ]
})
export class IfhmsAdminWebCoreFormlyTypesPricingSelectModule {}
