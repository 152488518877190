import { OperationDto } from '@ifhms/models/admin';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as reducer from './operation.reducer';

export const selectFeatureState =
  createFeatureSelector<reducer.State>('operation');

const selectState = createSelector(
  selectFeatureState,
  (state: reducer.State) => state
);

const selectOperation = createSelector(selectState, reducer.getOperation);

const selectOperationId = createSelector(selectState, reducer.getOperationId);
const selectUpdating = createSelector(selectState, reducer.getUpdating);
const selectUpdated = createSelector(selectState, reducer.getUpdated);
const selectLoading = createSelector(selectState, reducer.getLoading);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

const selectLocation = createSelector(
  selectOperation,
  (operation: OperationDto | null) => {
    if (operation) {
      return operation.location;
    } else {
      return {};
    }
  }
);

const selectOperationName = createSelector(
  selectOperation,
  (operation: OperationDto | null) => {
    if (operation) {
      return operation.name;
    } else {
      return '';
    }
  }
);

const selectOperationSlug = createSelector(
  selectOperation,
  (operation: OperationDto | null) => {
    if (operation) {
      return operation.slug;
    } else {
      return '';
    }
  }
);

const selectTagSettings = createSelector(selectState, reducer.getTagSettings);
const selectSortGroupSettings = createSelector(
  selectState,
  reducer.getSortGroupSettings
);
const selectCciaAccountSettings = createSelector(
  selectState,
  reducer.getCciaAccountSettings
);
const selectPermissions = createSelector(selectState, reducer.getPermissions);
const selectPmIntegrationSettings = createSelector(
  selectState,
  reducer.getPmIntegrationSettings
);
const selectDSortIntegrationSettings = createSelector(
  selectState,
  reducer.getDSortIntegrationSettings
);
// const selectLogo = createSelector(selectState, reducer.getLogo);
// const selectLogoMime = createSelector(selectState, reducer.getLogoMime);
const selectGeneralSettings = createSelector(
  selectState,
  reducer.getGeneralSettings
);

export const operationSelectors = {
  selectState,
  selectUpdated,
  selectUpdating,
  selectLoaded,
  selectLoading,
  selectGeneralSettings,
  selectOperation,
  selectOperationId,
  selectLocation,
  selectOperationName,
  selectOperationSlug,
  selectTagSettings,
  selectSortGroupSettings,
  selectCciaAccountSettings,
  selectPmIntegrationSettings,
  selectPermissions,
  selectDSortIntegrationSettings
  // selectLogo,
  // selectLogoMime
};
