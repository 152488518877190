import { Injectable } from '@angular/core';
import { AppContextDto } from '@ifhms/models/shared';
import { Store } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';
import { AcquisitionTypesFacade } from './acquisition-types/acquisition-types.facade';
import { AgeClassesFacade } from './age-classes/age-classes.facade';
import { BreedTypesFacade } from './breed-types/breed-types.facade';
import { BreedsFacade } from './breeds/breeds.facade';
import { CountriesFacade } from './countries/countries.facade';
import { DentitionsFacade } from './dentitions/dentitions.facade';
import { DoseUnitsFacade } from './dose-units/dose-units.facade';
import { DxGroupsFacade } from './dx-groups/dx-groups.facade';
import { FacilitiesFacade } from './facilities/facilities.facade';
import { FeedingProgramsFacade } from './feeding-programs/feeding-programs.facade';
import { GendersFacade } from './genders/genders.facade';
import { InjectionLocationsFacade } from './injection-locations/injection-locations.facade';
import { LabourChargeTypesFacade } from './labour-charge-types/labour-charge-types.facade';
import { LotOwnersFacade } from './lot-owners/lot-owners.facade';
import { LotSubGroupsFacade } from './lot-subgroups/lot-subgroups.facade';
import { LotsFacade } from './lots/lots.facade';
import { ManufacturersFacade } from './manufacturers/manufacturers.facade';
import { MarketingPlansFacade } from './marketing-plans/marketing-plans.facade';
import { PensFacade } from './pens/pens.facade';
import { PricingMethodsFacade } from './pricing-methods/pricing-methods.facade';
import { ProcessMethodsFacade } from './process-methods/process-methods.facade';
import { ProductTypesFacade } from './product-types/product-types.facade';
import { RiskCategoriesFacade } from './risk-categories/risk-categories.facade';
import { RoutesFacade } from './routes/routes.facade';
import { TagLocationsFacade } from './tag-locations/tag-locations.facade';
import { UnitsOfMeasureFacade } from './units-of-measure/units-of-measure.facade';
import { VendorsFacade } from './vendors/vendors.facade';
import { SortTypesFacade } from './sort-types/sort-types.facade';
import { TxGroupsFacade } from './tx-groups/tx-groups.facade';

@Injectable()
export class ReferenceDataFacade {
  acquisitionTypes$: Observable<SersiSelectListItem[]>;
  ageClasses$: Observable<SersiSelectListItem[]>;
  breedTypes$: Observable<SersiSelectListItem[]>;
  breeds$: Observable<SersiSelectListItem[]>;
  countries$: Observable<SersiSelectListItem[]>;
  dentitions$: Observable<SersiSelectListItem[]>;
  doseUnits$: Observable<SersiSelectListItem[]>;
  dxPmGroups$: Observable<SersiSelectListItem[]>;
  dxTxGroups$: Observable<SersiSelectListItem[]>;
  facilities$: Observable<SersiSelectListItem[]>;
  feedingPrograms$: Observable<SersiSelectListItem[]>;
  genders$: Observable<SersiSelectListItem[]>;
  homePens$: Observable<SersiSelectListItem[]>;
  injectionLocations$: Observable<SersiSelectListItem[]>;
  labourChargeTypes$: Observable<SersiSelectListItem[]>;
  lots$: Observable<SersiSelectListItem[]>;
  lotSubGroups$: Observable<SersiSelectListItem[]>;
  lotOwners$: Observable<SersiSelectListItem[]>;
  manufacturers$: Observable<SersiSelectListItem[]>;
  marketingPlans$: Observable<SersiSelectListItem[]>;
  pens$: Observable<SersiSelectListItem[]>;
  pricingMethods$: Observable<SersiSelectListItem[]>;
  processMethods$: Observable<SersiSelectListItem[]>;
  productTypes$: Observable<SersiSelectListItem[]>;
  riskCategories$: Observable<SersiSelectListItem[]>;
  routes$: Observable<SersiSelectListItem[]>;
  tagLocations$: Observable<SersiSelectListItem[]>;
  unitsOfMeasure$: Observable<SersiSelectListItem[]>;
  vendors$: Observable<SersiSelectListItem[]>;
  sortTypes$: Observable<SersiSelectListItem[]>;
  txGroups$: Observable<SersiSelectListItem[]>;

  constructor(
    private readonly store: Store,
    private acquisitionTypes: AcquisitionTypesFacade,
    private ageClassesFacade: AgeClassesFacade,
    private breedTypesFacade: BreedTypesFacade,
    private breedsFacade: BreedsFacade,
    private countriesFacade: CountriesFacade,
    private dentitionsFacade: DentitionsFacade,
    private doseUnitsFacade: DoseUnitsFacade,
    private dxGroupsFacade: DxGroupsFacade,
    private facilitiesFacade: FacilitiesFacade,
    private feedingProgramsFacade: FeedingProgramsFacade,
    private gendersFacade: GendersFacade,
    private injectionLocationsFacade: InjectionLocationsFacade,
    private labourChargeTypesFacade: LabourChargeTypesFacade,
    private lotsFacade: LotsFacade,
    private lotSubGroupsFacade: LotSubGroupsFacade,
    private lotOwnersFacade: LotOwnersFacade,
    private manufacturersFacade: ManufacturersFacade,
    private marketingPlansFacade: MarketingPlansFacade,
    private pensFacade: PensFacade,
    private pricingMethodsFacade: PricingMethodsFacade,
    private processMethodsFacade: ProcessMethodsFacade,
    private productTypesFacade: ProductTypesFacade,
    private riskCategoriesFacade: RiskCategoriesFacade,
    private routesFacade: RoutesFacade,
    private unitsOfMeasureFacade: UnitsOfMeasureFacade,
    private tagLocationsFacade: TagLocationsFacade,
    private vendorsFacade: VendorsFacade,
    private sortTypesFacade: SortTypesFacade,
    private txGroupsFacade: TxGroupsFacade
  ) {
    this.acquisitionTypes$ = this.acquisitionTypes.items$;
    this.ageClasses$ = this.ageClassesFacade.items$;
    this.breedTypes$ = this.breedTypesFacade.items$;
    this.breeds$ = this.breedsFacade.items$;
    this.countries$ = this.countriesFacade.items$;
    this.dentitions$ = this.dentitionsFacade.items$;
    this.doseUnits$ = this.doseUnitsFacade.items$;
    this.dxPmGroups$ = this.dxGroupsFacade.pmGroups$;
    this.dxTxGroups$ = this.dxGroupsFacade.txGroups$;
    this.facilities$ = this.facilitiesFacade.items$;
    this.feedingPrograms$ = this.feedingProgramsFacade.items$;
    this.genders$ = this.gendersFacade.items$;
    this.injectionLocations$ = this.injectionLocationsFacade.items$;
    this.labourChargeTypes$ = this.labourChargeTypesFacade.items$;
    this.lots$ = this.lotsFacade.items$;
    this.lotSubGroups$ = this.lotSubGroupsFacade.items$;
    this.lotOwners$ = this.lotOwnersFacade.items$;
    this.manufacturers$ = this.manufacturersFacade.items$;
    this.marketingPlans$ = this.marketingPlansFacade.items$;
    this.pens$ = this.pensFacade.items$;
    this.pricingMethods$ = this.pricingMethodsFacade.items$;
    this.processMethods$ = this.processMethodsFacade.items$;
    this.productTypes$ = this.productTypesFacade.items$;
    this.riskCategories$ = this.riskCategoriesFacade.items$;
    this.routes$ = this.routesFacade.items$;
    this.tagLocations$ = this.tagLocationsFacade.items$;
    this.unitsOfMeasure$ = this.unitsOfMeasureFacade.items$;
    this.vendors$ = this.vendorsFacade.items$;
    this.sortTypes$ = this.sortTypesFacade.items$;
    this.txGroups$ = this.txGroupsFacade.items$;

    this.homePens$ = this.pensFacade.getHomeLocations();
  }

  getAcquisitionTypes(): void {
    this.acquisitionTypes.get();
  }

  getAgeClasses(): void {
    this.ageClassesFacade.get();
  }

  getBreedTypes(): void {
    this.breedTypesFacade.get();
  }

  getBreeds(): void {
    this.breedsFacade.get();
  }

  getCountries(): void {
    this.countriesFacade.get();
  }

  getDentitions(): void {
    this.dentitionsFacade.get();
  }

  getDoseUnits(): void {
    this.doseUnitsFacade.get();
  }

  getDxGroups(): void {
    this.dxGroupsFacade.get();
  }

  getFacilities(context?: AppContextDto): void {
    this.facilitiesFacade.get(context);
  }

  getFeedingPrograms(): void {
    this.feedingProgramsFacade.get();
  }

  getGenders(): void {
    this.gendersFacade.get();
  }

  getLots(levelId?: string): void {
    this.lotsFacade.get(levelId);
  }

  getLotSubGroups(levelId?: string): void {
    this.lotSubGroupsFacade.get(levelId);
  }

  getLotOwners(): void {
    this.lotOwnersFacade.get();
  }

  getInjectionLocations(): void {
    this.injectionLocationsFacade.get();
  }

  getManufacturers(): void {
    this.manufacturersFacade.get();
  }

  getMarketingPlans(): void {
    this.marketingPlansFacade.get();
  }

  getPens(levelId?: string): void {
    this.pensFacade.get(levelId);
  }

  getPricingMethods(): Observable<SersiSelectListItem[]> {
    return this.pricingMethodsFacade.get();
  }

  getProductTypes(): void {
    this.productTypesFacade.get();
  }

  getRiskCategories(context?: AppContextDto): void {
    this.riskCategoriesFacade.get(context);
  }

  getRoutes(): void {
    this.routesFacade.get();
  }

  getTagLocations(): void {
    this.tagLocationsFacade.get();
  }

  getUnitsOfMeasure(): void {
    this.unitsOfMeasureFacade.get();
  }

  getVendors(): void {
    this.vendorsFacade.get();
  }

  getSortTypes(): void {
    this.sortTypesFacade.get();
  }

  getTxGroups(): void {
    this.txGroupsFacade.get();
  }
}
