import { OperationFeaturePermission } from '@ifhms/admin/web/domain/permissions';
import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';

export const getFeedlotNavigationMenuItems = (
  companySlug: string,
  feedlotSlug: string
): NavListBaseItem[] => [
  {
    key: 'overview',
    icon: 'dashboard',
    active: false,
    routerLink: [`/operation/${companySlug}/feedlot/${feedlotSlug}/overview`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    routerLink: [`/operation/${companySlug}/feedlot/${feedlotSlug}/users`],
    permissionList: [
      OperationFeaturePermission.UserManagementUsersRead,
      OperationFeaturePermission.UserManagementRolesRead,
      OperationFeaturePermission.UserManagementAccessRead
    ]
  },
  {
    key: 'reports',
    icon: 'insert_chart',
    active: false,
    routerLink: [`/operation/${companySlug}/feedlot/${feedlotSlug}/reports`],
    permissionList: [
      OperationFeaturePermission.ReportsAnimalTreatmentHistory,
      OperationFeaturePermission.ReportsDailyTreatmentHistoryByFacility,
      OperationFeaturePermission.ReportsLotUFDetail,
      OperationFeaturePermission.ReportsOtherDiseasesManagement,
      OperationFeaturePermission.ReportsPostMortemDetail,
      OperationFeaturePermission.ReportsPostMortemDetailByDate,
      OperationFeaturePermission.ReportsUFManagement,
      OperationFeaturePermission.ReportsCattleMovement,
      OperationFeaturePermission.ReportsEventDetails,
      OperationFeaturePermission.ReportsHospitalOccupancy,
      OperationFeaturePermission.ReportsLotExpensesSummary,
      OperationFeaturePermission.ReportsLotHealthSummary,
      OperationFeaturePermission.ReportsLotOtherDiseasesDetail,
      OperationFeaturePermission.ReportsPenAndLotInventory,
      OperationFeaturePermission.ReportsTagAvailability,
      OperationFeaturePermission.ReportsWithdrawal,
      OperationFeaturePermission.ReportsCurrentProductPricing,
      OperationFeaturePermission.ReportsProductOnHand,
      OperationFeaturePermission.ReportsProductUsage,
      OperationFeaturePermission.ReportsReconciledPhysicalInventoryValue,
      OperationFeaturePermission.ReportsArrivalWorkOrder,
      OperationFeaturePermission.ReportsLotTransferWorkOrder,
      OperationFeaturePermission.ReportsMovementWorkOrder,
      OperationFeaturePermission.ReportsRailWorkOrder,
      OperationFeaturePermission.ReportsRehandlingWorkOrder,
      OperationFeaturePermission.ReportsShipmentWorkOrder
    ]
  },
  {
    key: 'product-management',
    icon: 'inventory_2',
    active: false,
    routerLink: [
      `/operation/${companySlug}/feedlot/${feedlotSlug}/product-management`
    ],
    permissionList: [
      OperationFeaturePermission.ProductManagementDefinitionRead,
      OperationFeaturePermission.ProductManagementMappingRead,
      OperationFeaturePermission.ProductManagementPricingRead,
      OperationFeaturePermission.ProductManagementReceiptRead,
      OperationFeaturePermission.ProductManagementReconciliationRead,
      OperationFeaturePermission.ProductManagementHasLotNoExpiry,
      OperationFeaturePermission.ProductManagementHasTransactions
    ]
  },
  {
    key: 'treatment-protocols',
    icon: 'medical_services',
    active: false,
    routerLink: [
      `/operation/${companySlug}/feedlot/${feedlotSlug}/treatment-protocols`
    ],
    permissionList: ['enterprise']
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [
      `/operation/${companySlug}/feedlot/${feedlotSlug}/reference-data`
    ],
    permissionList: [OperationFeaturePermission.ReferenceDataRead]
  },
  {
    key: 'settings',
    icon: 'settings',
    active: false,
    routerLink: [`/operation/${companySlug}/feedlot/${feedlotSlug}/settings`],
    permissionList: [
      OperationFeaturePermission.SettingsGeneralRead,
      OperationFeaturePermission.SettingsPricingSettingsRead,
      OperationFeaturePermission.SettingsDefaultValuesRead,
      OperationFeaturePermission.SettingsArrivalRead,
      OperationFeaturePermission.SettingsReHandlingRead,
      OperationFeaturePermission.SettingsPostMortemRead,
      OperationFeaturePermission.SettingsLabourChargesRead,
      OperationFeaturePermission.SettingsProcedureChargesRead,
      OperationFeaturePermission.SettingsCustomFieldsManagementRead,
      OperationFeaturePermission.SettingsTagRead,
      OperationFeaturePermission.SettingsSortGroupRead,
      OperationFeaturePermission.SettingsSortGroupTemplatesRead,
      OperationFeaturePermission.SettingsCCIARead
    ]
  },
  {
    key: 'lot-billing',
    icon: 'receipt_long',
    active: false,
    routerLink: [
      `/operation/${companySlug}/feedlot/${feedlotSlug}/lot-billing`
    ],
    permissionList: [OperationFeaturePermission.LotBillingRead]
  }
];
