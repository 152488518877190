import {
  FeedlotGeneralSettingsDto,
  OperationDto,
  OperationSettingsCciaDto,
  OperationSettingsDSortIntegrationDto,
  OperationSettingsPmIntegrationDto,
  OperationSettingsSortGroupDto,
  OperationSettingsTagsDto
} from '@ifhms/models/admin';
import { FeedlotPermissionsDto } from '@ifhms/models/shared';
import { createReducer, on } from '@ngrx/store';
import { OperationActions } from './operation.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  updating: boolean;
  updated: boolean;
  operation: OperationDto | null;
}

const initialState: State = {
  loading: false,
  loaded: false,
  updating: false,
  updated: false,
  operation: null
};

export const reducer = createReducer(
  initialState,
  on(
    OperationActions.get,
    OperationActions.getCciaSettings,
    OperationActions.getGeneralSettings,
    OperationActions.getPmIntegrationSettings,
    (state) => ({
      ...state,
      loading: false,
      loaded: false,
      updating: true,
      updated: false
    })
  ),

  on(OperationActions.getSuccess, (state, action) => ({
    ...state,
    operation: action.operation,
    loading: false,
    loaded: true,
    updating: false,
    updated: false
  })),

  on(OperationActions.getBySlugSuccess, (state, action) => {
    const updatedOperation = {
      ...state.operation,
      ...action.operation
    };
    return {
      ...state,
      operation: action.updateCurrent ? updatedOperation : action.operation,
      loading: false,
      loaded: true,
      updating: false,
      updated: false
    };
  }),

  on(OperationActions.getGeneralSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      generalSettings: action.generalSettings
    },
    loading: false,
    loaded: true,
    updating: false,
    updated: false
  })),

  on(OperationActions.getGeneralSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      generalSettings: action.generalSettings
    },
    loading: false,
    loaded: true,
    updating: false,
    updated: false
  })),

  on(OperationActions.getCciaSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      cciaAccountSettings: action.cciaSettings
    },
    loading: false,
    loaded: true,
    updating: false,
    updated: false
  })),

  on(OperationActions.getPmIntegrationSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      pmIntegrationSettings: action.settings
    },
    loading: false,
    loaded: true,
    updating: false,
    updated: false
  })),

  on(
    OperationActions.update,
    OperationActions.updateGeneralSettings,
    OperationActions.updateCciaSettings,
    OperationActions.updatePmIntegrationSettings,
    OperationActions.updateSortGroupSettings,
    OperationActions.updateTagSettings,
    (state) => ({
      ...state,
      updating: true,
      updated: false
    })
  ),

  on(OperationActions.updateTagFormatForm, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      tagSettings: action.settings
    }
  })),

  on(OperationActions.updateSuccess, (state, action) => ({
    ...state,
    operation: action.operation,
    updating: false,
    updated: true
  })),

  on(OperationActions.updateGeneralSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      generalSettings: action.generalSettings
    },
    updating: false,
    updated: true
  })),

  on(OperationActions.updateCciaSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      cciaAccountSettings: action.cciaSettings
    },
    updating: false,
    updated: true
  })),

  on(OperationActions.updatePmIntegrationSettingsSuccess, (state, action) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      pmIntegrationSettings: action.settings
    },
    updating: false,
    updated: true
  })),

  on(OperationActions.resetGeneralSettings, (state) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      generalSettings: {
        operationId: '',
        logo: null,
        logoMime: null,
        name: '',
        slug: '',
        countryId: ''
      }
    }
  })),

  on(OperationActions.resetCciaSettings, (state) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      cciaAccountSettings: null
    }
  })),

  on(OperationActions.resetPmIntegrationSettings, (state) => ({
    ...state,
    operation: {
      ...(state.operation as OperationDto),
      pmIntegrationSettings: null
    }
  })),

  on(OperationActions.error, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    updating: false,
    updated: false,
    operation: null
  })),

  on(OperationActions.errorSettingsUpdate, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    updating: false,
    updated: false
  })),

  on(OperationActions.reset, () => initialState)
);

export const getState = (state: State): State => state;
export const getOperation = (state: State): OperationDto | null =>
  state.operation;
export const getOperationId = (state: State): string =>
  state.operation?.id || '';
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUpdating = (state: State): boolean => state.updating;
export const getUpdated = (state: State): boolean => state.updated;
export const getTagSettings = (state: State): OperationSettingsTagsDto | null =>
  state.operation?.tagSettings || null;
export const getSortGroupSettings = (
  state: State
): OperationSettingsSortGroupDto | null =>
  state.operation?.sortGroupSettings || null;
export const getCciaAccountSettings = (
  state: State
): OperationSettingsCciaDto | null =>
  state.operation?.cciaAccountSettings || null;
export const getPmIntegrationSettings = (
  state: State
): OperationSettingsPmIntegrationDto | null =>
  state.operation?.pmIntegrationSettings || null;
export const getDSortIntegrationSettings = (
  state: State
): OperationSettingsDSortIntegrationDto | null =>
  state.operation?.dSortIntegrationSettings || null;
export const getPermissions = (state: State): FeedlotPermissionsDto | null =>
  state.operation?.permissions || null;
export const getGeneralSettings = (
  state: State
): FeedlotGeneralSettingsDto | null => state.operation?.generalSettings || null;
