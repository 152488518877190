import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';

import { AhCommonDialogService } from '@common/angular/utils';
import { TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

import { ApiErrorType } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService implements ErrorHandler {

  private readonly translateScope = 'common-errors';

  error$ = new Subject<HttpErrorResponse>();

  private readonly messageService = inject(MessageService);
  private readonly dialogService = inject(AhCommonDialogService);
  private readonly translationService = inject(TranslocoService);

  handleError(error: Error | HttpErrorResponse, useDefaultErrAlerts = true): void {
    const isClientError = error instanceof Error;

    if (isClientError) {
      useDefaultErrAlerts && this.showToastErr(error.message);
      return;
    }

    const isCustomStatusStatus = error.status > 200 && error.status < 300;
    if (isCustomStatusStatus && useDefaultErrAlerts) {
      this.handleCustomStatusResponse(error);
    }

    this.error$.next(error);
  }

  private handleCustomStatusResponse(resp: HttpErrorResponse): void {
    switch (resp.status) {
      case ApiErrorType.ApiErrorException:
        return this.processApiErrorException(resp);
      case ApiErrorType.ApiErrorConfirmationException:
        return this.processApiErrorConfirmationException(resp);
      case ApiErrorType.ApiErrorAnimalNotAvailableException:
        return this.processApiErrorAnimalNotAvailableException(resp);
      default:
        console.log('Unhandled status', resp.status);
    }
  }

  private processApiErrorException(resp: HttpErrorResponse): void {
    this.showToastErr(resp.error.text);
  }

  private processApiErrorAnimalNotAvailableException(resp: HttpErrorResponse): void {

    const animalAvailabilityDto = resp.error;
    console.error('Animal not available', animalAvailabilityDto);

    // TODO: new animal availability modal
    this.dialogService.openErrorModal({
      header: this.getTranslation('confirmation-required-exception.title'),
      message: JSON.stringify(animalAvailabilityDto)
    });
  }

  private processApiErrorConfirmationException(resp: HttpErrorResponse): void {
    this.dialogService.openErrorModal({
      header: this.getTranslation('confirmation-required-exception.title'),
      message: resp.error.text || this.getTranslation('confirmation-required-exception.message')
    });
  }

  private showToastErr(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: this.getTranslation('generic-error.title'),
      detail: message || this.getTranslation('generic-error.message')
    });
  }

  private getTranslation(key: string): string {
    return this.translationService.translate(`${this.translateScope}.${key}`);
  }
}