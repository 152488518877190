import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OperationTypeCode } from '@ifhms/models/admin';

import * as OperationsReducer from './operations.reducer';

export const selectFeatureState = createFeatureSelector<OperationsReducer.State>(OperationsReducer.FEATURE_NAME);

const selectState = createSelector(
  selectFeatureState,
  (state: OperationsReducer.State) => state
);

const selectCreating = createSelector(selectState, OperationsReducer.getCreating);
const selectCreated = createSelector(selectState, OperationsReducer.getCreated);

const selectLoading = createSelector(selectState, OperationsReducer.getLoading);
const selectLoaded = createSelector(selectState, OperationsReducer.getLoaded);

const selectOperations = createSelector(selectState, OperationsReducer.getAll);
const selectFilter = createSelector(selectState, OperationsReducer.getFilter);

const selectFeedlotOperations = createSelector(
  selectOperations,
  selectFilter,
  (operations, filter) => {

    if (!operations || !Array.isArray(operations)) {
      return [];
    }

    if (!filter) {
      return operations;
    }

    return operations.filter((operation) => {
      const description = operation.description || '';
      const isMatchingSearch = !filter || description.toLowerCase().includes(filter.toLowerCase());
      const isFeedlot = operation.operationTypeCode === OperationTypeCode.Feedlot;
      return isFeedlot && isMatchingSearch;
    });
  }
);

export const OperationsSelectors = {
  selectCreating,
  selectCreated,
  selectLoading,
  selectLoaded,
  selectOperations,
  selectFeedlotOperations,
  selectFilter,
  selectState
};
