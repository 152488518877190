import {
  FeedlotGeneralSettingsDto,
  FeedlotGeneralSettingsUpdateDto,
  OperationDto,
  OperationSettingsCciaDto,
  OperationSettingsDSortIntegrationDto,
  OperationSettingsPmIntegrationDto,
  OperationSettingsSortGroupDto,
  OperationSettingsTagsDto,
  OperationUpdateDto
} from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[OPERATION]';

const get = createAction(`${NAMESPACE} Get`, props<{ operationId: string }>());

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ operation: OperationDto }>()
);

const getBySlug = createAction(
  `${NAMESPACE} Get By Slug`,
  props<{ slug: string; updateCurrent: boolean }>()
);

const getBySlugSuccess = createAction(
  `${NAMESPACE} Get By Slug Success`,
  props<{ operation: OperationDto; updateCurrent: boolean }>()
);

const update = createAction(
  `${NAMESPACE} Update`,
  props<{ operation: OperationUpdateDto }>()
);

const updateSuccess = createAction(
  `${NAMESPACE} Update Success`,
  props<{ operation: OperationDto }>()
);

// ===== General Settings =====
const getGeneralSettings = createAction(`${NAMESPACE} Get General Settings`);

const getGeneralSettingsSuccess = createAction(
  `${NAMESPACE} Get General Settings Success`,
  props<{ generalSettings: FeedlotGeneralSettingsDto }>()
);

const updateGeneralSettings = createAction(
  `${NAMESPACE} Update General Settings`,
  props<{ generalSettingsUpdate: FeedlotGeneralSettingsUpdateDto }>()
);

const updateGeneralSettingsSuccess = createAction(
  `${NAMESPACE} Update General Settings Success`,
  props<{ generalSettings: FeedlotGeneralSettingsDto }>()
);

const resetGeneralSettings = createAction(
  `${NAMESPACE} Reset General Settings`
);

// ===== CCIA Settings =====
const getCciaSettings = createAction(`${NAMESPACE} Get CCIA Settings`);

const getCciaSettingsSuccess = createAction(
  `${NAMESPACE} Get CCIA Settings Success`,
  props<{ cciaSettings: OperationSettingsCciaDto }>()
);

const updateCciaSettings = createAction(
  `${NAMESPACE} Update CCIA Settings`,
  props<{ cciaSettings: OperationSettingsCciaDto }>()
);

const updateCciaSettingsSuccess = createAction(
  `${NAMESPACE} Update CCIA Settings Success`,
  props<{ cciaSettings: OperationSettingsCciaDto }>()
);

const resetCciaSettings = createAction(`${NAMESPACE} Reset CCIA Settings`);

// ===== PM Integration Settings =====
const getPmIntegrationSettings = createAction(
  `${NAMESPACE} Get PM Integration Settings`
);

const getPmIntegrationSettingsSuccess = createAction(
  `${NAMESPACE} Get PM Integration Settings Success`,
  props<{ settings: OperationSettingsPmIntegrationDto }>()
);

const updatePmIntegrationSettings = createAction(
  `${NAMESPACE} Update PM Integration Settings`,
  props<{ settings: OperationSettingsPmIntegrationDto }>()
);

const updatePmIntegrationSettingsSuccess = createAction(
  `${NAMESPACE} Update PM Integration Settings Success`,
  props<{ settings: OperationSettingsPmIntegrationDto }>()
);

const resetPmIntegrationSettings = createAction(
  `${NAMESPACE} Reset PM Integration Settings`
);

const updateDSortIntegrationSettings = createAction(
  `${NAMESPACE} Update DSort Integration Settings`,
  props<{ settings: OperationSettingsDSortIntegrationDto }>()
);

const updateTagSettings = createAction(
  `${NAMESPACE} Update Tag Settings`,
  props<{ settings: OperationSettingsTagsDto }>()
);

const updateTagFormatForm = createAction(
  `${NAMESPACE} Update Tag Format Form`,
  props<{ settings: OperationSettingsTagsDto }>()
);

const updateSortGroupSettings = createAction(
  `${NAMESPACE} Update SortGroup Settings`,
  props<{ settings: OperationSettingsSortGroupDto }>()
);

const error = createAction(`${NAMESPACE} Error`);
const errorSettingsUpdate = createAction(`${NAMESPACE} Error Settings Update`);

const reset = createAction(`${NAMESPACE} Reset`);

export const OperationActions = {
  get,
  getSuccess,
  getBySlug,
  getBySlugSuccess,
  update,
  updateSuccess,
  getGeneralSettings,
  getGeneralSettingsSuccess,
  updateGeneralSettings,
  updateGeneralSettingsSuccess,
  resetGeneralSettings,
  getCciaSettings,
  getCciaSettingsSuccess,
  updateCciaSettings,
  updateCciaSettingsSuccess,
  resetCciaSettings,
  getPmIntegrationSettings,
  getPmIntegrationSettingsSuccess,
  updatePmIntegrationSettings,
  updatePmIntegrationSettingsSuccess,
  resetPmIntegrationSettings,
  updateDSortIntegrationSettings,
  updateTagSettings,
  updateTagFormatForm,
  updateSortGroupSettings,
  error,
  errorSettingsUpdate,
  reset
};
