import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Router } from '@angular/router';

import { CommonPermissionsMap, CommonPermissionsService } from '@common/angular/permissions';
import { getCurrentRouteWithData, transformPermission } from '@common/angular/utils';
import { OperationFacade } from '@ifhms/admin/web/domain/state/operation';

@Injectable({ providedIn: 'root' })
export class OperationPermissionsService extends CommonPermissionsService {

  constructor(
    private operationFacade: OperationFacade,
    private router: Router
  ) {
    super();
    this.permissions$ = this.operationFacade.permissions$.pipe(
      map(permissions => transformPermission(permissions))
    );
  }

  protected override getLoadedPermissions(): CommonPermissionsMap | null {
    return this.loadedPermissions || this.getRouteResolverPermissions();
  }

  private getRouteResolverPermissions(): CommonPermissionsMap | null {
    const routeData = getCurrentRouteWithData(this.router);
    return transformPermission(routeData['snapshot']?.data?.operation?.permissions);
  }

}
