import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SersiSelectListItem } from '@sersi/angular/formly/core';
import { Observable } from 'rxjs';

@Injectable()
export class EnterpriseUserRoleService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  getList(): Observable<SersiSelectListItem[]> {
    return this.http.get<SersiSelectListItem[]>(`${this.apiUrl}/Enterprise/Roles/SelectList`);
  }
}
