import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CompanyDto, CompanyPermissionsDto } from '@ifhms/models/admin';
import { getLoadedStateData } from '@common/angular/utils';

import { CompanyActions } from './company.actions';
import { State } from './company.reducer';
import { CompanySelectors } from './company.selectors';

@Injectable()
export class CompanyFacade {
  state$: Observable<State> = this.store.select(CompanySelectors.selectState);
  company$: Observable<CompanyDto | null> = this.store.select(CompanySelectors.selectCompany);
  companyId$: Observable<string> = this.store.select(CompanySelectors.selectCompanyId);
  companySlug$: Observable<string> = this.store.select(CompanySelectors.selectCompanySlug);
  companyName$: Observable<string> = this.store.select(CompanySelectors.selectCompanyName);

  isActive$: Observable<boolean> = this.store.select(CompanySelectors.selectIsActive);

  isLoading$: Observable<boolean> = this.store.select(CompanySelectors.selectLoading);
  isLoaded$: Observable<boolean> = this.store.select(CompanySelectors.selectLoaded);

  companyPermissions$: Observable<CompanyPermissionsDto | null> = this.store.select(
    CompanySelectors.selectCompanyPermissions
  );

  loadedCompany$ = getLoadedStateData<CompanyDto>(this.company$, this.isLoaded$);

  constructor(private store: Store<State>) {}

  get(slug: string, forceReload = true): void {
    this.store.dispatch(CompanyActions.get({ slug, forceReload }));
  }

  reset(): void {
    this.store.dispatch(CompanyActions.reset());
  }
}
