<div [ngClass]="styleClass">
  <p-menu #menu [model]="(items$ | async)!" [popup]="true" appendTo="body"></p-menu>
  <button
    pButton
    [attr.data-ele]="buttonAttrDataEle || 'add-menu-type-btn'"
    type="button"
    class="p-button-outlined"
    [class.hidden]="menu.model && menu.model.length === 0"
    (click)="menu.toggle($event)"
    [icon]="props['buttonIcon']"
    [label]="props['label'] || ''"
    [disabled]="props['disabled']">
  </button>
</div>