import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { ErrorHandlingInterceptor } from './interceptors';

@NgModule({
  imports: [CommonModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlingInterceptor,
    multi: true
  }]
})
export class CommonAngularErrorHandlingModule {}
