import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

import { ErrorHandlingService } from '../services';
import { USE_DEFAULT_ERROR_HANDLER } from '../interfaces';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  private readonly errorHandlingService = inject(ErrorHandlingService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlingService.handleError(error, request.context.get(USE_DEFAULT_ERROR_HANDLER));
        return throwError(error);
      })
    );
  }

}
