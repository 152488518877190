import { CompanyFeaturePermission } from '@ifhms/admin/web/domain/permissions';
import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';

export const getCompanyNavigationMenuItems = (companySlug: string): NavListBaseItem[] => ([
  {
    key: 'operations',
    icon: 'foundation',
    active: false,
    routerLink: [`/company/${companySlug}/operations`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    routerLink: [`/company/${companySlug}/user-management`],
    requiredPermission: CompanyFeaturePermission.UserManagement
  },
  {
    key: 'reports',
    icon: 'insert_chart',
    active: false,
    routerLink: [`/company/${companySlug}/reports/animal-treatment-history`],
    requiredPermission: CompanyFeaturePermission.Reports
  },
  {
    key: 'product-management',
    icon: 'inventory_2',
    active: false,
    routerLink: [`/company/${companySlug}/product-management`],
    requiredPermission: CompanyFeaturePermission.Products
  },
  {
    key: 'reference-data',
    icon: 'data_object',
    active: false,
    routerLink: [`/company/${companySlug}/reference-data`],
    requiredPermission: CompanyFeaturePermission.ReferenceData
  }
]);
